<!-- 申请专卖店 -->
<template>
  <div class="apply-container">
    <div class="main-title">开专卖店</div>
    <van-cell-group>
      <van-cell title="选择品类" class="brand-box">
        <van-radio-group v-model="brandId" direction="horizontal" checked-color="#a771ff">
          <van-radio :name="3">美妆</van-radio>
          <van-radio :name="4">大健康</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell :border="false">
        <div class="apply-prompt">
          需要完成一次{{ brandId === 3 ? '梵洁诗或香蜜' : '大健康'}}品牌12件自选商品混批采购订货，即可免费获得专卖店蜜蛋连锁店铺系统。
        </div>
      </van-cell>
      <van-cell class="pool-content">
        <van-checkbox v-model="agree" checked-color="#a771ff">我已阅读并同意<i @click.stop="showPoolLayer = true">《蜜蛋注册协议》</i></van-checkbox>
      </van-cell>
      <div class="operate">
        <van-button type="primary" color="#999999" round block v-if="getButtonText">{{getButtonText}}</van-button>
        <van-button type="primary" color="#bd9bfb" round block v-else @click="onSubmit">立即开店</van-button>
      </div>
    </van-cell-group>
    <mi-shop-right :brand-id="brandId" />
    <div class="share-box" v-if="token" @click="openAppShare">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211222184702371.png" />
    </div>
    <van-popup v-model="showPoolLayer" position="bottom" style="height:100%">
      <Protocol @close="closePoolLayer" />
    </van-popup>
    <van-popup v-model="showPushLayer">
      <fill-push-man sub-page="subPage" :brand-id="brandId" :inapp="!!token" @close="onPushClose" @open="isShowMiLayer = true" />
    </van-popup>
    <van-popup v-model="isShowMiLayer" :overlay="false">
      <div class="mi-manager">
        <span class="icon-delete" @click="isShowMiLayer = false"></span>
        <div class="box" v-if="qrCodeList">
          <template v-if="brandId === 3">
            <div class="head1">
              <img :src='qrCodeList.mzAgent.headImg' />
              <div class="name">蜜蛋管家小姐姐</div>
              <div class="name-na">{{qrCodeList.mzAgent.nikeName}}</div>
            </div>
            <img :src="qrCodeList.mzAgent.QRImg" />
          </template>
          <template v-else>
            <div class="head1">
              <img :src='qrCodeList.djkAgent.headImg' />
              <div class="name">蜜蛋管家小姐姐</div>
              <div class="name-na">{{qrCodeList.djkAgent.nikeName}}</div>
            </div>
            <img :src="qrCodeList.djkAgent.QRImg" />
          </template>
        </div>
        <p>扫一扫上面二维码图案，加我微信了解更多</p>
        <div class="clickbtn" @click="isShowCode()">选择蜜蛋店主孵化导师,一键开店</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Protocol from "../../components/Brand/Protocol"
import FillPushMan from "./component/FillPushMan"
import MiShopRight from "./component/MiShopRight"
import { replaceAppToken, deliveryMessage } from "../../utils/appUtil"
import { isEmpty } from "../../utils/public"
import storage from "../../store/storage"
export default {
  name: 'applyGreen',
  components: { Protocol, FillPushMan, MiShopRight },
  data() {
    return {
      brandId: 3,
      agree: false,
      brandList: [],
      showPoolLayer: false,
      showPushLayer: false, //推荐人弹框
      userIdentityNew: null,//身份信息
      subPage: '',//上一个页面
      token: '',
      qrCodeList: null,
      isShowMiLayer: false, //蜜蛋小姐姐
    }
  },
  shareUserId: 0,
  created() {
    this.$router.push("/");
    return;
    let { brandId, token, subPage, fillIn } = this.$route.query;
    if (brandId) {
      this.brandId = brandId == '4' ? 4 : 3;
    }
    this.subPage = subPage || '';
    if (fillIn === '1') this.showPushLayer = true;
    if (token) {
      this.token = token;
      replaceAppToken(token, () => {
        this.initFun();
      })
    } else {
      this.initFun();
    }
  },
  methods: {
    initFun() {
      this.getServiceList();
      //this.getUserAgentInfo();
      this.getUserIdentityNew();
    },
    getServiceList: function () {
      this.get("/TeamAgent/PlatformAgent/GetPlatformAgent").then(json => {
        if (json && json.code == 1) {
          let { pinkAgent } = json.response;
          this.qrCodeList = pinkAgent;
        }
      });
    },
    getUserIdentityNew: function () {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true })
      this.get("/TeamAgent/AgentInfo/HomeIdentity_V4").then(json => {
        this.$toast.clear();
        if (json && json.code === 1) {
          let data = json.response;
          let brandId = this.brandId;
          let { mzState, jkState } = data;
          if (brandId === 3 && mzState === 1) {
            brandId = 4;
          }
          if (brandId === 4 && jkState === 1) {
            brandId = 3;
          }
            this.brandId = brandId;
          this.userIdentityNew = data;
        }
      })
    },
    closePoolLayer() {
      this.showPoolLayer = false;
    },
    onPushClose() {
      this.showPushLayer = false;
    },
    isShowCode: function () {
      let { mzAgent, djkAgent } = this.qrCodeList;
      let phoneNum = this.brandId === 3 ? mzAgent.agentPho : djkAgent.agentPho
      window.location = this.userHost + '/rolorweb/html/recharge/register.html?bid=' + this.brandId + '&phone=' + phoneNum + '&token=' + this.token;
    },
    //立即开店
    onSubmit() {
      if (!this.agree) return this.$dialog.alert({ title: "提示", message: '请勾选我已阅读并同意《蜜蛋网注册协议》' });
      this.showPushLayer = true
    },
    //调用app分享
    openAppShare: function () {
      let { jkState, mzState } = this.userIdentityNew;
      if (jkState != 1 && mzState != 1) return this.$dialog.alert({ title: '提示', message: "亲爱的蜜蛋宝宝您暂时无法匹配成为开店的邀请人，所以无法分享噢！" })
      let basePath = this.baseHost + '/7999';
      let message = {
        action: 'jumpNativeShare',
        data: {
          url: this.baseHost + '/#/apply/greenShare?shareUserId=' + this.shareUserId,
          title: '开专卖店',
          desc: '蜜蛋连锁邀请您一起来开专卖店!',
          iconUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211229163447168.png',
          drawPath: basePath + '/Redesign/html/applyStore/onLinePoster.html?type=1&token=' + this.token
        }
      };
      deliveryMessage(message);
    },
  },
  computed: {
    getButtonText() {
      let { userIdentityNew, brandId } = this;
      if (!userIdentityNew) return '';
      //-1非代理  0：待审核，1：已审核， 3：未建立经销体系 4：黑名单，5：取消授权
      let { mzRequest, jkRequest, mzState, jkState, levelTitle } = userIdentityNew;
      let state = brandId === 3 ? mzState : jkState;
      if (state === 1) {
        return `您已开启${brandId == 3 ? '美妆' : '大健康'}${levelTitle}店`;
      }
      if (brandId === 3 && mzRequest > 1) {
        return `您的美妆${mzRequest === 2 ? '专卖店' : '旗舰店'}正在申请中`;
      } else if (brandId === 4 && jkRequest > 1) {
        return `您的大健康${mzRequest === 2 ? '专卖店' : '旗舰店'}正在申请中`;
      }
      return ''
    }
  }
}
</script>
<style lang='scss' scoped>
@import '../../assets/style/applyStore';
.van-popup {
  border-radius: 8px;
}
.mi-manager {
  width: 300px;
  padding: 15px 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 12px #999;

  .box {
    width: calc(100% - 58px);
    margin: auto;

    .head1 {
      overflow: hidden;
      margin-bottom: 10px;

      img {
        width: 50px;
        float: left;
        margin-right: 6px;
      }

      .name {
        margin-top: 8px;
        font-size: 12px;
        font-weight: bold;
        //line-height: 14px;
        color: #666666;
      }

      .name-na {
        //line-height: 14px;
        color: #999999;
      }

      &:after {
        content: '';
        clear: both;
      }
    }

    > img {
      max-width: 100%;
    }
  }

  .clickbtn {
    text-decoration: underline;
    color: #ff98a0;
    text-align: center;
    margin-top: 40px;

    span {
      font-size: 10px;
    }
  }

  p {
    color: #999;
    font-size: 12px;
    margin-bottom: 0;
    text-align: center;
    margin-top: 5px;
  }

  .icon-user {
    color: #e4877c;

    &:before {
      content: '\e929';
    }
  }

  .icon-delete {
    position: absolute;
    top: 0;
    right: 0;
    color: #333333;
    padding: 12px;
    font-size: 12px;

    &:before {
      content: '\e9c5';
    }
  }
}
</style>