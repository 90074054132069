const trim = (str) => {
  if (str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  }
  return str;
}
const regTel = (val) => {
  let telReg = /^[1][3456789][0-9]{9}$/;
  return telReg.test(val);
}
const randomNum = function (minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}
const isEmpty = function (str) {
  if (str instanceof String) {
    str = this.trim(str);
  }
  if (str == null || str == "" || str == undefined || str == [] || str == "undefined") {
    return true;
  } else if (str instanceof Object) {
    var hasProp = false;
    for (var prop in str) {
      hasProp = true;
      break;
    }
    if (!hasProp) return true;
  }
  return false;
}
export {
  trim, regTel, randomNum, isEmpty
}