<!-- 蜜蛋注册协议 -->
<template>
  <div class="content">
    <h3>“蜜蛋”注册协议<span class="mui-icon icon-delete-cha" @click="cancelShow"></span></h3>
    <div class="cont-desc" ref="contEl">
      <p>【审慎阅读】您在申请注册流程中点击“立即申请”前，应当认真阅读以下协议。请您务必审慎阅读、充分理解协议中相关条款内容，其中包括</p>
      <ul>
        <li>1、与您约定免除或限制责任的条款；</li>
        <li>2、与您约定法律适用和管辖的条款；</li>
        <li>3、其他以粗体下划线标识的重要条款。</li>
      </ul>
      <p><span>【特别提示】 您已充分理解，“蜜蛋”是一个商业贸易采购平台，而并非面向消费者的消费购买市场，当您按照注册页面提示填写信息、阅读
          并同意协议且完成全部注册程序后，即表示您已充分阅读、理解并接受协议的全部内容，并将基于您的真实贸易需求而使用“蜜蛋”服务。
        </span>如您因蜜蛋服务与蜜蛋络公司发生争议，应适用《蜜蛋服务条款》处理。</p>
      <p>阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止注册程序。</p>
      <div v-for="(item, index) in poolList" :key="index">
        <p>《{{ item.title }}》详情
          <span @click="clickExpand(index)">
            点击 - {{ agreeIndex === index ? '收起' : '展开' }}
          </span>
        </p>
        <div class="agree-list" v-if="agreeIndex === index" v-html="item.content">
        </div>
      </div>
      <p class="pad-bottom">如您对协议有任何疑问，可向蜜蛋客服咨询。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Protocol',
  data() {
    return {
      poolList: [],
      agreeIndex: -1,
      contEl: null
    }
  },
  created() {
    this.getPoolInfo();
  },
  mounted() {
    this.contEl = this.$refs.contEl;
  },
  methods: {
    //获取协议内容
    getPoolInfo() {
      this.get("/Base/PublicTools/GetAgreementManageList", { typeId: 5 }, 2).then(({ code, response }) => {
        if (code === 1) {
          this.poolList = response;
        }
      })
    },
    cancelShow() {
      this.$emit("close")
    },
    clickExpand(index) {
      if (index === this.agreeIndex) return this.agreeIndex = -1;
      this.agreeIndex = index;
      this.$nextTick(() => {
        this.contEl.scrollTo(0, 0)
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.content {
  padding: 10px;
  position: relative;
  height: calc(100% - 20px);
  text-align: left;
  overflow-y: hidden;
  box-sizing: border-box;

  h3 {
    font-size: 18px;
    color: #333;
  }

  .icon-delete-cha {
    font-size: 14px;
    color: #9b9b9b;
    position: absolute;
    right: 10px;
    top: 0;
    padding: 10px;
  }

  .cont-desc {
    position: absolute;
    top: 48px;
    z-index: 100;
    overflow: auto;
    height: 100%;
    left: 15px;
    right: 15px;
    bottom: 60px;

    p {
      font-size: 12px;
      color: #666;

      &.pad-bottom {
        padding-bottom: 100px;
      }
    }

    ul {
      li {
        text-decoration: underline;
        line-height: 25px;
        list-style: none;
        //font-weight: 600;
      }
    }

    span {
      text-decoration: underline;

      //font-weight: 600;
      &.rig {
        float: right;
      }
    }

    .agree-list {
      margin-top: 10px;
      padding-top: 10px;
      font-size: 14px;
      border-top: 1px dashed #ccc;

      h3 {
        font-size: 14px;
      }

      p {
        font-size: 12px;
        color: #333;

        em {
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}

.agree-btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  text-align: center;
  padding: 10px 0;
  z-index: 120;

  .btn {
    font-size: 12px;
    color: #fff;
    background: #ffb5bb;
    border: none;
    display: inline-block;
    border-radius: 30px;
    width: 70%;
    height: 35px;
    line-height: 35px;

    span {
      color: red;
    }
  }
}
</style>