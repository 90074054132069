<!-- 填写推荐人 -->
<template>
  <div class="apply-member">
    <div class="mui-layer">
      <span class="icon-delete icon-delete-cancel" @click="handleClose"></span>
      <div class="p-tip-name">请填写推荐人</div>
      <div class="p-tip-tx">请填写您的推荐人手机号，如果没有推荐人请<br />
        咨询<span @click="openMiLayer">蜜蛋管家小姐姐</span><br />
        若已有导师，请联系导师确认推荐人
      </div>
      <div class="mui-box">
        <div class="mui-input-row">
          <input type="number" class="phone" placeholder="请输入推荐人手机号" noscroll ref="reCommendPhone" v-model="phoneNum">
          <van-loading type="spinner" v-show="isLoadComment" />
        </div>
        <div class="foreign">
          <div class="label" @click="isAbroad = !isAbroad">国外<span style="color: #666">(非国外手机号请勿点击)</span></div>
          <van-checkbox v-model="isAbroad" checked-color="#ffb5bb" @change="changePhoneType"></van-checkbox>
        </div>
        <template v-if="reCommentInfo">
          <div v-if="reCommentInfo.superior" class="agent-info">
            <img :src="reCommentInfo.superior.serverAgentHeadImg ? reCommentInfo.superior.serverAgentHeadImg :require('../../../assets/images/100.png')" />
            <div class="media-body">
              <div><span>{{reCommentInfo.superior.serverAgentName}}</span>(采购店铺)</div>
              <div>{{reCommentInfo.superior.serverAgengPhone}}</div>
              <div>{{reCommentInfo.superior.serverLevelName}}</div>
              <div>{{reCommentInfo.superior.authCode}}</div>
            </div>
          </div>
          <div class="agent-info">
            <img :src="reCommentInfo.serverAgentHeadImg ? reCommentInfo.serverAgentHeadImg :require('../../../assets/images/100.png')" />
            <div class="media-body">
              <div><span>{{reCommentInfo.serverAgentName}}</span></div>
              <div>{{reCommentInfo.serverLevelName}}</div>
              <div>{{reCommentInfo.authCode}}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="mui-btn-row">
        <a href="javascript:" class="mui-btn false" @click="handleClose">取消</a>
        <a v-if="reCommentInfo" href="javascript:" class="mui-btn sure" @click="sureReCommend">确定</a>
        <a v-else href="javascript:" class="mui-btn sure" @click="getReCommend">点击查询</a>
      </div>
    </div>

  </div>
</template>

<script>
import { isEmpty, trim } from "../../../utils/public"
import { deliveryMessage } from "../../../utils/appUtil"
export default {
  name: '',
  props: {
    subPage: {
      type: String,
      value: ''
    },
    inapp: {
      type: Boolean,
      value: false
    },
    brandId: {
      type: Number,
      value: 3
    }
  },
  data() {
    return {
      phoneNum: "",
      isAbroad: false,
      isLoadComment: false,
      reCommentInfo: null,
    }
  },
  created() {

  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    changePhoneType: function () {
      if (this.isLoadComment) return;
      this.getReCommend();
    },
    getReCommend: function () { //获取推荐人信息
      window.scrollTo(0, 0);
      var str = this.phoneNum;
      if (isEmpty(str)) return this.phoneNum = "";
      if (isNaN(str)) return this.phoneNum = "";
      this.isLoadComment = 1;
      let phone = trim(str);
      if (!this.isAbroad) {
        if (phone.length < 11) return this.isLoadComment = 0;
        if (phone.length > 11) {
          this.isLoadComment = 0;
          this.$dialog.alert({ title: "提示", message: '代理手机号有误!' });
        }
      }
      this.getCommendInfo(phone, this.brandId);
    },
    getCommendInfo: function (val, bid) {
      this.post("/MemberAgent/Info/QueryParentByPushPhone", {
        tel: val, bid: bid, t: new Date().getTime()
      }).then(json => {
        this.isLoadComment = 0;
        if (json && json.code == 1) {
          let obj = json.response.find(item => {
            return item.brandId === bid && item.serverAgentId > 0
          });
          if (obj) {
            this.reCommentInfo = obj;
          } else {
            this.reCommentInfo = null;
            this.$dialog.alert({ title: "提示", message: '没找到代理信息!' });
          }
        } else {
          this.reCommentInfo = null;
          this.$dialog.alert({ title: "提示", message: json.msg });
        }
      })

    },
    sureReCommend: function () { //确认推荐人
      var info = this.reCommentInfo;
      if (!info) return this.$dialog.alert({ title: "提示", message: '请填写邀请人!' });
      if (info.authCode) {
        let brandTypeId = this.brandId == 4 ? 4 : 13;
        let str = window.localStorage.agentPhoByBrand || "";
        let obj = {};
        try {
          obj = JSON.parse(str);
        } catch (e) {
          obj = {};
        }
        obj[brandTypeId] = info.serverAgengPhone;
        window.localStorage.agentPhoByBrand = JSON.stringify(obj);
        if (this.subPage === '1') {
          //跳回购物车
          window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/shop_cart.html";
        } else if (this.subPage === '2') {
          window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/confirm_order.html";
        } else {
          if (this.inapp) {
            let message = {
              'action': 'registerGreenResult',
              'data': {
                'refereePhone': info.serverAgengPhone,
                'brandTypeId': this.brandId
              }
            };
            deliveryMessage(message);
          } else {
            window.localStorage.greenPurchaseTip = "";
            window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?brandId=" + this.brandId;
          }

        }
      }

    },
    openMiLayer() {
      this.$emit("open")
    }
  }
}
</script>
<style lang='scss' scoped>
.apply-member {
  .mui-layer {
    width: 300px;
    z-index: 601;
    min-height: 400px;
    overflow-y: auto;
    text-align: center;
    padding: 11px 15px;
    border-radius: 8px;
    box-sizing: border-box;
    background: white;
    color: #666;

    .icon-delete {
      position: absolute;
      width: 20px;
      line-height: 20px;
      text-align: center;
      height: 20px;
      background: #f1f1f1;
      border-radius: 50%;
      top: 12px;
      right: 15px;
      color: #666666;
      font-weight: normal;
      font-size: 10px;

      &:before {
        content: '\e9c5';
      }
    }

    .p-tip-name {
      text-align: center;
      padding-bottom: 15px;
      color: #666666;
      font-size: 16px;
    }

    .p-tip-tx {
      font-size: 12px;
      color: #999999;
      line-height: 24px;

      span {
        text-decoration: underline;
        color: #ff98a0;
      }
    }

    .p-tips {
      a {
        color: #ffb5bb;
        text-decoration: underline;
      }
    }

    .mui-box {
      width: 80%;
      min-height: 140px;
      margin: 6% auto 0;
      position: relative;
      border-radius: 8px;
      .mui-input-row {
        position: relative;
        background: #f8f8f8;

        input {
          outline: 0;
          border: none;
          width: 100%;
          height: 40px;
          font-size: 12px;
          padding: 10px 15px;
          background-color: #f8f8f8;
          -webkit-appearance: none;
          box-sizing: border-box;

          &.phone {
            //border-bottom: 1px dashed #d5a559;
          }
        }

        .van-loading--spinner {
          position: absolute;
          top: 8px;
          right: 15px;
          width: 20px;
          height: 20px;
        }
      }

      .foreign {
        padding: 10px 15px;
        border-top: 1px solid white;
        position: relative;
        background-color: #f8f8f8;
        text-align: left;
        display: flex;
        align-items: center;
        .label {
          padding-right: 5px;
        }
      }

      .agent-info {
        overflow: hidden;
        text-align: left;
        line-height: 24px;
        padding: 6px 15px;
        background: #f8f8f8;
        border-top: 1px solid white;

        > img {
          width: 33px;
          height: 33px;
          float: left;
          border-radius: 50%;
          margin-right: 10px;
        }

        .media-body {
          width: calc(100% - 43px);
          overflow: hidden;
        }

        &:last-child {
          margin-top: 5px;
        }

        &:after {
          content: '';
          clear: both;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: -7px;
        left: -7px;
        right: -7px;
        bottom: 0;
        z-index: -1;
        border-radius: 8px;
      }
    }

    .mui-btn-row {
      padding: 6% 0 3%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mui-btn {
        line-height: 33px;
        width: 130px;
        height: 33px;
        color: #fff;
        border: none;
        font-size: 14px;
        border-radius: 30px;

        &.false {
          border: 1px solid #e5e5e5;
          color: #666666;
          background-color: #ffffff;
        }

        &.sure {
          color: #fff;
          background-color: #ffb5bb;
        }
      }
    }
  }
}
</style>